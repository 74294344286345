@import "variables.scss"; @import "mixins.scss";
.gridColumnsLeft {
  grid-column: 1 / 7;
  @include respond-tablet {
    grid-column: 1 / 7;
    padding-bottom: $spacingL;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
    padding-bottom: $spacingL;
  }
}

.gridColumnsRight {
  grid-column: 7 / 13;
  @include respond-tablet {
    grid-column: 1 / 7;
    padding-bottom: $spacingL;
  }
  @include respond-mobile {
    grid-column: 1 / 5;
    padding-bottom: $spacingL;
  }
}

.textInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: $spacingS;
  padding-bottom: $spacingS;
  @include respond-mobile {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.textWithTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
}
