@import "variables.scss"; @import "mixins.scss";
.numberFormatInput {
  font-size: 18px;
  background-color: $lightorangeopacity;
  border-radius: 4px;
  border: none;
  color: $darkgray;
  width: 150px;
  height: 52px;
  text-align: center;
  margin-left: $spacingM;
}
