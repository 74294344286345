@import "variables.scss"; @import "mixins.scss";
.tabs {
  display: flex;
  flex-direction: row;
  padding-top: $spacingL;
  padding-bottom: $spacingL;
}

.button {
  font-family: CircularPro;
  font-size: $fontSizeBody;
  font-weight: bold;
  display: flex;
  justify-content: center;
  border-radius: 200px;
  padding: 16px;
  cursor: "pointer";
  letter-spacing: 0.5px;
  line-height: 22px;
  min-width: 170px;
  border: 0px;
  background-color: $transparent;
  color: $darkgray;
  transition: all 0.1s linear;
  &:hover {
    cursor: pointer;
    &.selected {
      box-shadow: $box-shadow;
    }
  }
}

.selected {
  background-color: $orange;
  color: $silver;
}
