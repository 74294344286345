@import "variables.scss"; @import "mixins.scss";
.container {
  width: '100%';
  display: flex;
  .slider {
    -webkit-appearance: none;
    appearance: none;
    flex: 1;
    height: 7px;
    border-radius: 4px;
    background: $lightorange;
    :hover {
      opacity: 1;
    }
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: $sliderHandleSize;
    height: $sliderHandleSize;
    border-radius: 20px;
    background: $orange;
    border-color: $orange;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: $sliderHandleSize;
    height: $sliderHandleSize;
    border-radius: 20px;
    background: $orange;
    border-color: $orange;
    cursor: pointer;
  }
}
