@import "variables.scss"; @import "mixins.scss";
.container {
  h4 {
    @include respond-mobile {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
    }
  }
}

.buttonContainer {
  margin: 48px 0;
  display: flex;
}

.mobileCenter {
  @include respond-mobile {
    display: flex;
    justify-content: center;
  }
}
