@import "variables.scss"; @import "mixins.scss";
.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $spacingML;
  margin-bottom: $spacingML;

  max-height: 90px;
  .sliderBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    .sliderText {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: $spacingML;
    }
    .slider {
      padding-top: $spacingM;
      padding-bottom: $spacingM;
    }
  }
  .calculatorInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    @include respond-mobile {
      display: none;
    }
  }
}
