@import "variables.scss"; @import "mixins.scss";
.button {
  width: 264px;
  margin-top: 10%;
  order: 1;
  a {
    color: $darkgray;
  }
}

.yellowAccentColor {
  p > strong,
  h1 {
    margin-top: $spacingS;
    strong {
      color: $yellow;
    }
  }
  p {
    color: $silver;
  }
}

.blueAccentColor {
  p > strong,
  h1 {
    margin-top: $spacingS;
    strong {
      color: $babyblue;
    }
  }
  p {
    color: $silver;
  }
}

.redAccentColor {
  p > strong,
  h1 {
    margin-top: $spacingS;
    strong {
      color: $red;
    }
  }
  p {
    color: $silver;
  }
}

.tab {
  width: 72px;
  height: $spacingXS;
  border-radius: 200px;
  margin-right: $spacingML;
  opacity: 0.4;
}

.selected {
  opacity: 1;
}

.tabsWrapper {
  display: flex;
  margin-top: 10%;
  margin-bottom: $spacingXL;
  order: 2;
}

.rightGridItem {
  align-items: flex-end;
  order: 0;
  align-items: start;
}

.leftGridItem {
  order: 1;
}

.tabWrapper {
  padding-top: $spacingM;
  padding-bottom: $spacingM;
  background: transparent;
  border: none;
}

.motionContainer {
  height: 60%;
  padding-top: $spacingL2;
  order: 0;
}

@media all and (max-width: 600px) {
  .rightGridItem {
    align-items: flex-end;
    order: 1;
  }

  .lefGridItem {
    order: 0;
  }

  .tabsWrapper {
    order: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 96px;
    margin-bottom: 0;
    a div {
      margin-right: 0;
      width: 23vw;
    }
  }

  .motionContainer {
    order: 1;
    padding-top: 0;
    height: 50%;
  }

  .button {
    order: 2;
    width: 100%;
    margin-top: 0;
    a {
      color: $darkgray;
    }
  }
}

.lottieBannerResponsive {
  background-color: $transparent;
  @media (max-width: 1024px) {
    height: 500px !important;
  }
  @media (max-width: 599px) {
    height: 276px !important;
  }
}
