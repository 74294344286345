@import "variables.scss"; @import "mixins.scss";
.container {
  background-color: $darkgray;
  border-radius: 8px;
  padding: $spacingML;
  flex: 1;

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: $spacingS;
    padding-bottom: $spacingS;
  }

  .textInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: $spacingS;
    padding-bottom: $spacingS;
    @include respond-mobile {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .divider {
    height: 2px;
    border-bottom: 2px solid $silver;
    margin-top: $spacingM;
    margin-bottom: $spacingM;
  }

  .textWithTooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .createAccountButtonContainer {
    display: flex;
    margin-top: 16px;
  }
}
