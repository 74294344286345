@import "variables.scss"; @import "mixins.scss";
.navigation {
  @include mainGrid;
  background-color: $darkgray;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.pageContent {
  position: relative;
  .content {
    overflow-x: hidden;
    padding: 0;
  }

  .footer {
    @include mainGrid;
    background-color: $darkgray;
    grid-column: 1 / span 3;
  }

  .mobileMenu {
    background-color: $darkgray;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}

.topInfoBanner {
  background-color: $lightorange;
  display: flex;
  justify-content: center;
  padding-top: $spacingML;
  padding-bottom: $spacingML;
  color: $darkgray;
  padding-left: $spacingS;
  padding-right: $spacingS;

  @media (max-width: 599px) {
    font-size: $fontSizeBodysmall;
    line-height: $fontSizeBodysmall;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    padding-top: $spacingM;
    padding-bottom: $spacingM;
  }
}
