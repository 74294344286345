@import "variables.scss"; @import "mixins.scss";
.faqGridItem {
  grid-column-end: span 4;
  @include respond-tablet {
    grid-column-end: span 2;
  }
  @include respond-small-tablet {
    grid-column-end: span 3;
  }
  @include respond-mobile {
    grid-column-end: span 4;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @include respond-mobile {
    flex-direction: row;
    margin-bottom: $spacingML;
  }

  .image {
    position: relative;
    height: 174px;
    width: 174px;
    margin-top: $spacingL2;
    margin-bottom: $spacingL;
    @include respond-mobile {
      height: 100%;
      width: fit-content;
      min-width: $spacingXL;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: $spacingM;
    }
  }

  .title {
    font-size: $fontSizeLarge;
    font-weight: bold;
    color: $darkgray;
    text-align: center;
    @include respond-mobile {
      margin-top: $spacingM;
      font-size: 25px;
      text-align: left;
    }
  }

  .title:after {
    content: '';
    display: block;
    margin: auto;
    margin-top: $spacingS;
    height: $spacingS;
    width: 0px;
    background: transparent;
    transition: width 0.5s ease, background-color 0.5s ease;
  }

  &:hover {
    cursor: pointer;
    .title::after {
      width: 100%;
      background: $red;
    }
  }
}
