@import "variables.scss"; @import "mixins.scss";
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: $spacingS;

  .item {
    display: flex;
    justify-content: center;
    margin-left: $spacingL;

    @media (max-width: 1100px) {
      margin-left: $spacingM;
    }
    @media (max-width: 1050px) {
      margin-left: $spacingS;
    }

    a {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $white-gamma;

      @media (max-width: 1050px) {
        font-size: 18px;
      }
      @include respond-tablet {
        font-size: 12px;
      }
    }
  }
  @include respond-tablet-mobile {
    display: none;
  }
}
