@mixin respond-tablet {
  @media (min-width: $tabletMin) and (max-width: $tabletMax) {
    @content;
  }
}

@mixin respond-small-mobile {
  @media (max-width: $smallMobileMax) {
    @content;
  }
}

@mixin respond-mobile {
  @media (max-width: $mobileMax) {
    @content;
  }
}

@mixin respond-tablet-mobile {
  @media (max-width: $tabletMax) {
    @content;
  }
}

@mixin respond-small-tablet {
  @media (min-width: $mobileMax) and (max-width: $tabletMid) {
    @content;
  }
}

@mixin respond-between-tablet-and-desktop {
  @media (min-width: $desktopMin) and (max-width: $desktopMid) {
    @content;
  }
}

@mixin respond-desktop {
  @media (min-width: $desktopMin) {
    @content;
  }
}

@mixin respond-desktop-tablet {
  @media (min-width: $tabletMin) {
    @content;
  }
}

@mixin mainGridDisplay {
  display: grid;
  grid-template-areas: '. content .';
  grid-column-gap: 0px;
  @include respond-between-tablet-and-desktop {
    grid-template-columns: minmax($gutterTabletDesktop, auto) minmax(0px, 1fr) minmax($gutterTabletDesktop, auto);
  }
  @include respond-mobile {
    grid-template-columns: minmax($gutterMobile, auto) minmax(0px, 1fr) minmax($gutterMobile, auto);
  }
  @include respond-small-mobile {
    grid-template-columns: minmax($gutterSmallMobile, auto) minmax(0px, 1fr) minmax($gutterSmallMobile, auto);
  }
}

@mixin mainGrid {
  @include mainGridDisplay;
  grid-template-columns: minmax($gutterDesktop, auto) minmax(auto, $desktopMax) minmax($gutterDesktop, auto);
  @include respond-tablet {
    grid-template-columns: minmax($gutterTablet, auto) minmax(0px, 1fr) minmax($gutterTablet, auto);
  }
}

@mixin mainGridWithLeftGutter {
  @include mainGridDisplay;
  grid-template-columns: minmax($gutterDesktop, 1fr) minmax(auto, $desktopMax) minmax($gutterOff, 1fr);
  @include respond-tablet {
    grid-template-columns: minmax($gutterTablet, auto) minmax(0px, 1fr) minmax($gutterOff, auto);
  }
}

@mixin mainGridWithRightGutter {
  @include mainGridDisplay;
  grid-template-columns: minmax($gutterOff, 1fr) minmax(auto, $desktopMax) minmax($gutterDesktop, 1fr);
  @include respond-tablet {
    grid-template-columns: minmax($gutterOff, 1fr) minmax(0px, 1fr) minmax($gutterTablet, 1fr);
  }
}

@mixin mainGridWithNoGutters {
  @include mainGridDisplay;
  grid-template-columns: minmax($gutterOff, auto) minmax(auto, $desktopMax) minmax($gutterOff, auto);
  @include respond-tablet {
    grid-template-columns: minmax($gutterOff, auto) minmax(0px, 1fr) minmax($gutterOff, auto);
  }
}

@mixin contentGrid {
  display: grid;
  position: relative;
  grid-column-gap: $gridGap;
  grid-template-columns: repeat(12, [col] 1fr);
  @include respond-tablet {
    grid-template-columns: repeat(6, [col] 1fr);
  }

  @include respond-mobile {
    grid-template-columns: repeat(4, [col] 1fr);
  }
}

@mixin fullMainGridWidth {
  grid-column: 1 / span 3;
}

@mixin mainGridContent {
  grid-area: content;
}

@mixin fullContentGridWidth {
  grid-column: 1 / span 13;
  @include respond-tablet {
    grid-column: 1 / span 7;
  }

  @include respond-mobile {
    grid-column: 1 / span 5;
  }
}
