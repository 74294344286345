@import "variables.scss"; @import "mixins.scss";
.footerColumn {
  justify-content: flex-start;
  height: 320px;
  width: 100%;
  @include respond-mobile {
    min-height: unset;
    height: auto;
  }

  a {
    display: block;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.3px;
  }

  .contact {
    font-size: 16px;
    font-weight: normal;
    line-height: 26px;
    color: $silver;
    margin-bottom: $spacingS;
    max-width: 260px;
  }
  .cta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $silver;
    font-size: 22px;
    font-weight: normal;
    line-height: 28px;
    height: $spacingXXXL;
    @include respond-mobile {
      margin-bottom: 16px;
      height: $spacingXL;
    }
  }
  .links {
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: $spacingM;
    a {
      line-height: 165%;
    }
  }
  .telephone {
    padding-left: 13px;
    a {
      font-size: 20px;
    }
  }
  .email {
    padding-left: 13px;
    a {
      font-size: 20px;
    }
  }
  .hideInMobile {
    @include respond-mobile {
      display: none;
    }
  }
  .showInMobile {
    @include respond-desktop-tablet {
      display: none;
    }
  }
}

.loginButtonContainer {
  @include respond-tablet {
    display: none;
  }
  > div {
    @include respond-mobile {
      margin-right: $spacingXXL;
    }
  }
}
