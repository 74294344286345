@import "variables.scss"; @import "mixins.scss";
.container {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px;
  border: 2px solid transparent;
  color: $silver;
  transition: all 0.2s linear;

  &:hover {
    box-shadow: $box-shadow;
  }
  a {
    font-weight: bold;
    flex: 1;
    padding: 14px $spacingM;
  }
  a:hover {
    cursor: pointer;
    text-decoration: none;
  }
}
