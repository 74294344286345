@import "variables.scss"; @import "mixins.scss";
@mixin buttonFlex {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonContainer {
  @include buttonFlex;
}
.buttonContainerMid {
  @include buttonFlex;
  margin-left: $spacingM;
  margin-right: $spacingM;
}
.buttonSubtitle {
  padding-top: $spacingS;
}
