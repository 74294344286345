$mobile: 750px;

$babyblue: #77c4d2;
$silver: #f5f5f5;
$black: #000000;
$blue: #287fe8;
$cardgray: #2a3241;
$darkgray: #252b39;
$green: #4cc97f;
$lightblue: #88c2d0;
$lightcardgray: #374053;
$lightgray: #aaadb3;
$lightorange: #f9855d;
$lightorangeopacity: #f6946655;
$neutralblack: #151522;
$orange: #ff6e3c;
$red: #f06478;
$transparent: #ffffff00;
$white: #ffffff;
$yellow: #f0d23c;
$orange: #ff6e3c;

$white-beta: #dbdbdb;
$white-gamma: #f5f5f5;

$cardgray-beta: #2a3241;
$cardgray-gamma: #3f4551;
$cardgray-delta: #374053;
$cardgray-eta: #252b39;

$smallMobileMax: 350px;
$mobileMax: 599px;
$tabletMin: 600px;
$tabletMid: 750px;
$tabletMax: 979px;
$desktopMin: 980px;
$desktopMid: 1150px;
$desktopMax: 1440px;

$spacingXS: 4px;
$spacingS: 8px;
$spacingM: 16px;
$spacingML: 24px;
$spacingL: 32px;
$spacingL2: 48px;
$spacingXL: 64px;
$spacingXXL: 88px;
$spacingXXXL: 128px;
$spacingXXXXL: 164px;

$gutterOff: 0px;
$gutterDesktop: 156px;
$gutterTabletDesktop: 78px;
$gutterTablet: 48px;
$gutterSmallMobile: 16px;
$gutterMobile: 24px;

$gridGap: 24px;

$fontSizeSmall: 12px;
$fontSizeBodysmall: 14px;
$fontSizeBody: 16px;
$fontSizeBodylarge: 18px;
$fontSizeH1: 28px;
$fontSizeH2: 22px;
$fontSizeLarge: 28px;
$fontSize24: 24px;
$fontSize32: 32px;
$fontSizeXl: 34px;

$smallSliderHandleSize: 16px;
$sliderHandleSize: 37px;
$navigationHeaderHeight: 152px;
$navigationHeaderHeightMobile: 112px;

$box-shadow:
    4px 4px 12px rgba(0, 0, 0, 0.22),
    8px 4px 22px rgba(0, 0, 0, 0.11);
