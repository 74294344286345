@import "variables.scss"; @import "mixins.scss";
.mainGridContainer {
  @include fullMainGridWidth;
  @include mainGrid;
}

.mainGridContainerWithLeftGutter {
  @include fullMainGridWidth;
  @include mainGridWithLeftGutter;
}

.mainGridContainerWithRightGutter {
  @include fullMainGridWidth;
  @include mainGridWithRightGutter;
}

.mainGridContainerWithNoGutter {
  @include fullMainGridWidth;
  @include mainGridWithNoGutters;
}
