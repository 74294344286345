@import "variables.scss"; @import "mixins.scss";
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: $fontSizeBody;
  line-height: 19px;
  border-radius: 200px;
  border: 2px solid transparent;
  background-color: $darkgray;
  color: $silver;
  padding: 16px;
  cursor: pointer;
  letter-spacing: 0.5px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.2s linear;
  &:hover {
    box-shadow: $box-shadow;
  }
  &.disabled {
    opacity: 0.6;
    cursor: default;
    :hover {
      opacity: 0.6;
    }
  }
  &.block {
    display: block;
    width: 100%;
  }
}
