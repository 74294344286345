@import "variables.scss"; @import "mixins.scss";
@mixin underline {
  display: block;
  margin-top: 6px;
  height: 3px;
  align-self: center;
  transition:
    width 0.5s ease,
    background-color 0.5s ease;
}
.linkContainer {
  a {
    display: inline-block;

    > div {
      display: flex;
      flex-direction: column;

      .underline {
        @include underline;
        background-color: transparent;
        width: 0px;
      }
    }
  }

  &:hover {
    a {
      .underline {
        width: 100%;
        background-color: $lightblue;
      }
    }
  }
}

.linkContainer.selected {
  a {
    display: inline-block;
    .underline {
      @include underline;
      width: 100%;
      background-color: $lightblue;
    }
  }
}

.disabled {
  color: $lightgray;
}
