@import "variables.scss"; @import "mixins.scss";
.imageContainer {
  width: auto;
  height: auto;
  background-color: transparent;
  display: flex;
  justify-content: center;
  @include respond-mobile {
    justify-content: center;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  @include respond-mobile {
    width: 100%;
    height: 100%;
  }
}
