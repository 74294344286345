@import "variables.scss"; @import "mixins.scss";
.outerContainer {
  @include fullMainGridWidth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: $navigationHeaderHeight;
  z-index: 100;

  @include respond-desktop {
    padding-left: $spacingXL;
    padding-right: $spacingXL;
  }
  @include respond-between-tablet-and-desktop {
    padding-left: $spacingL;
    padding-right: $spacingL;
  }
  @include respond-tablet {
    @include mainGridContent;
  }
  @include respond-mobile {
    @include mainGridContent;
    height: $navigationHeaderHeightMobile;
  }
}

.navigationContainer {
  display: flex;
  background-color: transparent;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: "100%";
  overflow-y: hidden;
  .logoContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
  }

  .navigationButtonContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;

    .loginButtons {
      display: flex;
      flex-direction: row;
      margin: 0 $spacingM;
      justify-content: space-between;

      > div {
        margin-left: 16px;
        white-space: nowrap;

        &.whiteFill {
          a {
            color: $cardgray-eta;
          }

          &:hover {
            background-color: $white !important;
          }

          &:active {
            background-color: $white-beta !important;
          }
        }

        &.darkFill {
          a {
            color: $white-gamma;
          }

          &:hover {
            background-color: rgba(245, 245, 245, 0.1) !important;
          }

          &:active {
            background-color: rgba(245, 245, 245, 0.1) !important;
            a {
              color: $lightgray !important;
            }
          }
        }

        a {
          padding: 14px $spacingL;
          @include respond-small-tablet {
            padding: 14px $spacingM;
            font-size: $fontSizeBodysmall;
          }
        }
      }

      @include respond-mobile {
        display: none;
      }
    }

    .mobileLoginButton {
      display: none;
      @include respond-mobile {
        display: block;
      }
    }
    .mobileHamburgerButton {
      display: none;
      @include respond-tablet-mobile {
        margin-left: $spacingM;
        display: block;
      }
    }
  }
}

/* Fix issue where logo didn't display in Firefox because these elements had 0 width. */
.logo {
  min-width: 100px;

  > a {
    width: 100%;
    > div {
      width: 100%;
    }
  }
}
